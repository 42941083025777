var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-landing" },
    [
      _c(
        "v-container",
        {
          staticClass: "login-view__container ma-0 pa-0 fill-height",
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { staticClass: "fill-height pa-0 ma-0" },
            [
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$vuetify.breakpoint.mdAndUp,
                      expression: "$vuetify.breakpoint.mdAndUp",
                    },
                  ],
                  staticClass: "pa-0",
                  attrs: { cols: "6" },
                },
                [
                  _c("image-panel", {
                    attrs: {
                      src: "https://storage.googleapis.com/drumnow-apps_public/images/subscribe-landing.jpg",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { cols: "12", sm: "10", md: "6" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dn-landing__form-section dn-landing__form-section--header",
                    },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade-transition", mode: "out-in" } },
                        [
                          _vm.formState === "login"
                            ? _c("logo", {
                                staticClass: "dn-landing__logo",
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.$router.push({ name: "home" })
                                  },
                                },
                              })
                            : _c("back-button", {
                                on: {
                                  back: function ($event) {
                                    _vm.formState = "login"
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dn-landing__form-section dn-landing__form-section--main",
                    },
                    [
                      _c(
                        "transition-group",
                        { attrs: { name: "slide-x-transition" } },
                        [
                          _vm.formState === "login"
                            ? _c(
                                "v-form",
                                {
                                  key: "login",
                                  staticClass: "dn-landing__form",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleLogin.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("h2", [_vm._v("Welcome Back")]),
                                  _c("text-input", {
                                    attrs: {
                                      type: "email",
                                      label: "Email",
                                      placeholder: "hello@drumnow.io",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c("text-input", {
                                    attrs: {
                                      type: "password",
                                      label: "Password",
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.formState = "forgot"
                                        },
                                      },
                                    },
                                    [_vm._v("Forgot your password?")]
                                  ),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "fade-transition",
                                        mode: "out-in",
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple",
                                                },
                                              ],
                                              staticClass:
                                                "dn-btn dn-landing__form__submit",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.disableLogin,
                                              },
                                            },
                                            [_vm._v(" Login ")]
                                          )
                                        : _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.formState === "forgot"
                            ? _c(
                                "v-form",
                                {
                                  key: "forgot",
                                  staticClass: "dn-landing__form",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleForgotPassword.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("h2", [_vm._v("Forgot Password")]),
                                  _c("text-input", {
                                    attrs: {
                                      type: "email",
                                      label: "Email",
                                      placeholder: "hello@drumnow.io",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "fade-transition",
                                        mode: "out-in",
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple",
                                                },
                                              ],
                                              staticClass:
                                                "dn-btn dn-landing__form__submit",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.disableForgot,
                                              },
                                            },
                                            [_vm._v(" submit ")]
                                          )
                                        : _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm.formState === "new"
                            ? _c(
                                "v-form",
                                {
                                  key: "create",
                                  staticClass: "dn-landing__form",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleCreate.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("h2", [_vm._v("Sign Up")]),
                                  _c("text-input", {
                                    attrs: {
                                      type: "email",
                                      label: "Email",
                                      placeholder: "hello@drumnow.io",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c("text-input", {
                                    attrs: {
                                      type: "password",
                                      label: "Password",
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                  _c("p", { staticClass: "mt-12" }, [
                                    _vm._v(" By signing up, you agree to our "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: "https://drumnow.com/legal#terms-of-service",
                                        },
                                      },
                                      [_vm._v("Terms of Service")]
                                    ),
                                    _vm._v(" and "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: "https://drumnow.com/legal#privacy-policy",
                                        },
                                      },
                                      [_vm._v("Privacy Policy")]
                                    ),
                                  ]),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "fade-transition",
                                        mode: "out-in",
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple",
                                                },
                                              ],
                                              staticClass:
                                                "dn-btn dn-landing__form__submit",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.disableCreate,
                                              },
                                            },
                                            [_vm._v(" Sign Up ")]
                                          )
                                        : _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  key: "waiting",
                                  staticClass: "dn-landing__form",
                                },
                                [
                                  _c("h1", [_vm._v("Verify your Email")]),
                                  _c("p", [
                                    _vm._v(
                                      " Please check your inbox for a link to verify your email address. "
                                    ),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("transition", { attrs: { name: "fade-transition" } }, [
                    _vm.formState !== "waiting" && _vm.formState !== "new"
                      ? _c("div", { staticClass: "dn-landing__form-section" }, [
                          _c("p", [_vm._v("Don't have an account?")]),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.formState = "new"
                                },
                              },
                            },
                            [_vm._v("Sign up for free")]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }