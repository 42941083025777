var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dn-text-input" }, [
    _c("fieldset", { staticClass: "dn-text-input__wrapper" }, [
      _c("label", { staticClass: "dn-text-input__label", attrs: { for: "" } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c(
        "input",
        _vm._b({ on: { input: _vm.update } }, "input", _vm.inputAttrs, false)
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }